import CircleArrowLeft from '@/assets/icons/icon-circle-arrow-left.svg?component';
import type { VNode } from 'vue';
import { NuxtLink } from '#components';

export default defineComponent({
  name: 'MoreHeader',
  props: {
    to: {
      type: [String],
      required: false,
      default: 'more',
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  render(): VNode {
    return (
      <div class="flex bg-gray-100 shadow-sm print:hidden">
        {this.$props.to === '#back' ? (
          <a
            onClick={() => this.router.back()}
            href={this.$props.to}
            class="text-rb-green! flex-1 items-center p-4! no-underline! opacity-100!"
          >
            <CircleArrowLeft height="24" width="24" />
            <h1 class="pl-4 text-lg font-semibold">
              {this.$slots.default?.()}
            </h1>
          </a>
        ) : (
          <NuxtLink
            to={{ name: this.$props.to }}
            class="text-rb-green! flex-1 items-center p-4! no-underline! opacity-100!"
          >
            <CircleArrowLeft height="24" width="24" />
            <h1 class="pl-4 text-lg font-semibold">
              {this.$slots.default?.()}
            </h1>
          </NuxtLink>
        )}
      </div>
    );
  },
});
